import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class SportsListService {
	constructor(private http: HttpClient) {}
	// Dashboard
	getDashboard() {
		return this.http
			.get<any>(`${environment.apiEndPoint}/vendor/fitness/dashboard/`, {
				headers: new HttpHeaders(`${environment.httpHeader}`)
			})
			.pipe(
				map(branch => {
					// console.log('<><><><><><>', branch);
					return branch;
				})
			);
	}

	getAllbranchs() {
		return this.http
			.get<any>(`${environment.apiEndPoint}/vendor/fitness/branch/`, {
				headers: new HttpHeaders(`${environment.httpHeader}`)
			})
			.pipe(
				map(branch => {
					// console.log('<><><><><><>', branch);
					return branch;
				})
			);
	}

	getAlltrainner() {
		return this.http
			.get<any>(`${environment.apiEndPoint}/vendor/fitness/trainer/`, {
				headers: new HttpHeaders(`${environment.httpHeader}`)
			})
			.pipe(
				map(branch => {
					// console.log('<><><><><><>', branch);
					return branch;
				})
			);
	}

	getdashboardis(queryParams?: object) {
		let params = new HttpParams();
		for (const key in queryParams) {
			if (queryParams.hasOwnProperty(key) && queryParams[key] !== '') {
				if (!params.has(key)) {
					params = params.set(key, queryParams[key]);
					// console.log("dp",params);
				}
			}
		}
		return this.http.get(
			`${
				environment.apiEndPoint
			}/vendor/fitness/dashboard/?${params.toString()}`
		);
	}

	////////////////////////////////////////////////////////////////

	// Reservations
	getinsights() {
		return this.http
			.get<any>(`${environment.apiEndPoint}/vendor/fitness/resinsights/`, {
				headers: new HttpHeaders(`${environment.httpHeader}`)
			})
			.pipe(
				map(branch => {
					// console.log('<><><><><><>', branch);
					return branch;
				})
			);
	}

	getBranch(Branchid) {
		return this.http
			.get<any>(
				`${
					environment.apiEndPoint
				}/vendor/fitness/course/?branchId=${Branchid} `,
				{
					headers: new HttpHeaders(`${environment.httpHeader}`)
				}
			)
			.pipe(
				map(branch => {
					// console.log('<><><><><><>', branch);
					return branch;
				})
			);
	}

	gettrainner(object) {
		return this.http
			.get<any>(
				`${environment.apiEndPoint}/vendor/fitness/trainernames/?branchId=${
					object.branchId
				}&courseId=${object.courseId} `,
				{
					headers: new HttpHeaders(`${environment.httpHeader}`)
				}
			)
			.pipe(
				map(trainer => {
					// console.log("<><><><><><>",trainer);
					return trainer;
				})
			);
	}
	getclaasse(object) {
		return this.http
			.get<any>(
				`${environment.apiEndPoint}/fitness/class/?courseId=${
					object.courseId
				}&branchId=${object.branchId}&trainerId=${object.trainerId} `,
				{
					headers: new HttpHeaders(`${environment.httpHeader}`)
				}
			)
			.pipe(
				map(classs => {
					// console.log('<><><><><><>', classs);
					return classs;
				})
			);
	}

	createsubscription(data) {
		return this.http
			.post<any>(
				`${environment.apiEndPoint}/vendor/fitness/subscription/`,
				data
			)
			.pipe(
				map(reservationscreate => {
					return reservationscreate;
				})
			);
	}

	getSubscription(queryParams?: object) {
		let params = new HttpParams();
		for (const key in queryParams) {
			if (queryParams.hasOwnProperty(key) && queryParams[key] !== '') {
				if (!params.has(key)) {
					params = params.set(key, queryParams[key]);
					// console.log("dp",params);
				}
			}
		}
		return this.http.get(
			`${
				environment.apiEndPoint
			}/vendor/fitness/subscription/?${params.toString()}`
		);
	}

	// event service
	loadSports() {
		return this.http
			.get<any>(`${environment.apiEndPoint}/vendor/fitness/courseList/`, {
				headers: new HttpHeaders(`${environment.httpHeader}`)
			})
			.pipe(
				map(courserList => {
					return courserList;
				})
			);
  }

  // event details apis

  getEventDetails(eventid) {
		return this.http
			.get<any>(`${environment.apiEndPoint}/vendor/fitness/course/${eventid}/`, {
				headers: new HttpHeaders(`${environment.httpHeader}`)
			})
			.pipe(
				map(events => {
					console.log(events, 'sda');
					return events;
				})
			);
  }



  // trainner data
  loadTrainners() {
		return this.http
			.get<any>(`${environment.apiEndPoint}/vendor/fitness/trainer/`, {
				headers: new HttpHeaders(`${environment.httpHeader}`)
			})
			.pipe(
				map(trainners => {
					return trainners;
				})
			);
  }

  getTrainerDetails(trainid) {
		return this.http
			.get<any>(`${environment.apiEndPoint}/vendor/fitness/fullTrainer/${trainid}/`, {
				headers: new HttpHeaders(`${environment.httpHeader}`)
			})
			.pipe(
				map(trainners => {
					// console.log(trainners, 'sda');
					return trainners;
				})
			);
  }

  filterSports(queryParams?: object) {
		let params = new HttpParams();
		for (const key in queryParams) {
			if (queryParams.hasOwnProperty(key) && queryParams[key] !== '') {
				if (!params.has(key)) {
					params = params.set(key, queryParams[key]);
				}
			}
		}
		return this.http.get(
			`${environment.apiEndPoint}/vendor/fitness/courseList/?${params.toString()}`
		);
  }

  ReservationList(queryParams?: object) {
		let params = new HttpParams();
		for (const key in queryParams) {
			if (queryParams.hasOwnProperty(key) && queryParams[key] !== '') {
				if (!params.has(key)) {
					params = params.set(key, queryParams[key]);
				}
			}
		}
		return this.http.get(
			`${environment.apiEndPoint}/vendor/fitness/subscription/?${params.toString()}`
		);
  }

  markAttendance(data) {
		return this.http
			.post<any>(
				`${environment.apiEndPoint}/vendor/fitness/attendance/`,
				data
			)
  }

  getDashboardData(queryParams?: object) {
		let params = new HttpParams();
		for (const key in queryParams) {
			if (queryParams.hasOwnProperty(key) && queryParams[key] !== '') {
				if (!params.has(key)) {
					params = params.set(key, queryParams[key]);
				}
			}
		}
		return this.http.get(
			`${environment.apiEndPoint}/vendor/fitness/dashboard/?${params.toString()}`
		);
  }

  getDashboardMemberShip(queryParams?: object) {
		let params = new HttpParams();
		for (const key in queryParams) {
			if (queryParams.hasOwnProperty(key) && queryParams[key] !== '') {
				if (!params.has(key)) {
					params = params.set(key, queryParams[key]);
				}
			}
		}
		return this.http.get(
			`${environment.apiEndPoint}/vendor/fitness/resinsights/?${params.toString()}`
		);
  }

  addInvit(data) {
		return this.http
			.post<any>(
				`${environment.apiEndPoint}/vendor/fitness/invitees/`,
				data
			)
  }

  getInvitData(eventid) {
  return this.http
  .get<any>(`${environment.apiEndPoint}/vendor/fitness/invitees/?subsc_Id=${eventid}`, {
    headers: new HttpHeaders(`${environment.httpHeader}`)
  })
  .pipe(
    map(events => {
      console.log(events, 'sda');
      return events;
    })
    )
  }

  getCourseDateTime(eventid) {
    return this.http
    .get<any>(`${environment.apiEndPoint}/vendor/fitness/attendance/?res_id=${eventid}`, {
      headers: new HttpHeaders(`${environment.httpHeader}`)
    })
    .pipe(
      map(events => {
        console.log(events, 'sda');
        return events;
      })
      )
    }

    checkNumberExist(queryParams?: object) {
      let params = new HttpParams();
      for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key) && queryParams[key] !== '') {
          if (!params.has(key)) {
            params = params.set(key, queryParams[key]);
          }
        }
      }
      return this.http.get(
        `${environment.apiEndPoint}/vendor/fitness/findinvitees/?${params.toString()}`
      );
    }

}
