import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToursService {
  constructor(private http: HttpClient) { }
  loadTour() {
    return this.http
      .get<any>(`${environment.apiEndPoint}/vendor/tour/`, {
        headers: new HttpHeaders(`${environment.httpHeader}`)
      })
      .pipe(
        map(tours => {
          return tours;
        })
      );
  }
  loadEvents() {
    return this.http
      .get<any>(`${environment.apiEndPoint}/vendor/restaurant/branch/event/?page=1&size=30`, {
        headers: new HttpHeaders(`${environment.httpHeader}`)
      })
      .pipe(
        map(tours => {
          return tours;
        })
      );
  }
  filterTour(queryParams?: object) {
    let params = new HttpParams();
    for (const key in queryParams) {
      if (queryParams.hasOwnProperty(key) && queryParams[key] !== '') {
        if (!params.has(key)) {
          params = params.set(key, queryParams[key]);
        }
      }
    }
    return this.http.get(
      `${environment.apiEndPoint}/vendor/tour/?${params.toString()}`
    );
  }

  getTourDetails(tourid) {
    return this.http
      .get<any>(`${environment.apiEndPoint}/vendor/tour/?tour_id=${tourid}`, {
        headers: new HttpHeaders(`${environment.httpHeader}`)
      })
      .pipe(
        map(tours => {
          console.log(tours, 'sda');
          return tours;
        })
      );
  }
  getTimeslotsbyDate(data) {
    return this.http
      .get<any>(
        `${environment.apiEndPoint}/vendor/gettimeslots/?tour_id=${data.tourid
        }&date=${data.date}`,
        {
          headers: new HttpHeaders(`${environment.httpHeader}`)
        }
      )
      .pipe(
        map(slots => {
          return slots;
        })
      );
  }
  getReservationsPerSlot(ID) {
    return this.http
      .get<any>(
        `${environment.apiEndPoint
        }/vendor/tourslotreservations/?slot_guid=${ID}`,
        {
          headers: new HttpHeaders(`${environment.httpHeader}`)
        }
      )
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  loadCities() {
    return this.http
      .get<any>(`${environment.apiEndPoint}/vendor/city`, {
        headers: new HttpHeaders(`${environment.httpHeader}`)
      })
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  loadArea(cityId) {
    return this.http
      .get<any>(`${environment.apiEndPoint}/distirct/city/${cityId}/`, {
        headers: new HttpHeaders(`${environment.httpHeader}`)
      })
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  addBasicInfoTour(tours?: object) {
    let params = new HttpParams();
    for (const key in tours) {
      if (tours.hasOwnProperty(key) && tours[key] !== '') {
        if (!params.has(key)) {
          params = params.set(key, tours[key]);
          // console.log("dp",params);
        }
      }
    }
    return this.http.post(
      `${environment.apiEndPoint}/vendor/tour/vendorDetails/`,
      { tours }
    );
  }

  getWeekDays() {
    return this.http
      .get<any>(`${environment.apiEndPoint}/daysofweek/tour`, {
        headers: new HttpHeaders(`${environment.httpHeader}`)
      })
      .pipe(
        map(response => {
          console.log(response);
          return response;
        })
      );
  }
  getWeekDayscancel() {
    return this.http
      .get<any>(`${environment.apiEndPoint}/daysofweek/`, {
        headers: new HttpHeaders(`${environment.httpHeader}`)
      })
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  postTrips(data) {
    return this.http
      .post<any>(`${environment.apiEndPoint}/vendor/tour/trip/`, data)
      .pipe(
        map(trips => {
          console.log('TTTT', trips);
          return trips;
        })
      );
  }
  addEvent(data) {
    return this.http
      .post<any>(`${environment.apiEndPoint}/vendor/restaurant/branch/event/`, data)
      .pipe(
        map(info => {
          console.log('<><>><><><>', info);
          return info;
        })
      );

  }
  getEventId(id) {
    return this.http
      .get<any>(`${environment.apiEndPoint}/vendor/restaurant/branch/event/?event=${id}&page=1&size=1`, {
        headers: new HttpHeaders(`${environment.httpHeader}`)
      })
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  postAddInfo(data) {
    return this.http
      .post<any>(`${environment.apiEndPoint}/vendor/tourformadditional/`, data)
      .pipe(
        map(info => {
          console.log('<><>><><><>', info);
          return info;
        })
      );
  }

  getPaymentMethod() {
    return this.http
      .get<any>(`${environment.apiEndPoint}/payment/methods/`, {
        headers: new HttpHeaders(`${environment.httpHeader}`)
      })
      .pipe(
        map(response => {
          // console.log(response,'payment')
          return response;
        })
      );
  }
  getBranchData() {
    return this.http
      .get<any>(`${environment.apiEndPoint}/vendor/restaurant/branch/`, {
        headers: new HttpHeaders(`${environment.httpHeader}`)
      })
      .pipe(
        map(response => {
          // console.log(response,'payment')
          return response;
        })
      );
  }

  getWorkFlowPayment(paymentId) {
    return this.http
      .get<any>(
        `${environment.apiEndPoint
        }/vendor/listworkflows/?hasPayment=${paymentId}`,
        {
          headers: new HttpHeaders(`${environment.httpHeader}`)
        }
      )
      .pipe(
        map(response => {
          // console.log(response,'payment')
          return response;
        })
      );
  }

  postWorkFlow(data, tourid) {
    return this.http
      .post<any>(
        `${environment.apiEndPoint
        }/vendor/tourpaymentdetails/?tour_id=${tourid}`,
        data
      )
      .pipe(
        map(WF => {
          console.log('<><>><><><>', WF);
          return WF;
        })
      );
  }

  editTourBasicInfo(data, tourid) {
    data = { tour_guid: tourid, tours: data };
    return this.http
      .post<any>(`${environment.apiEndPoint}/vendor/tour/vendorDetails/`, data)
      .pipe(
        map(sv => {
          console.log('<><>><><><>', sv);
          return sv;
        })
      );
  }

  addNewtourpacakge(tourid, data) {
    data = { packages: data };
    return this.http
      .post<any>(
        `${environment.apiEndPoint}/vendor/tour/trip/update/${tourid}/`,
        data
      )
      .pipe(
        map(up => {
          console.log('<><>><><><>', up);
          return up;
        })
      );
  }

  editTourDetailsADD(tourid, data) {
    data = { additional_Information: data };
    return this.http
      .post<any>(
        `${environment.apiEndPoint}/vendor/tour/extraDetails/${tourid}/`,
        data
      )
      .pipe(
        map(up => {
          console.log('<><>><><><>', up);
          return up;
        })
      );
  }

  addWorkFlow(data, tourid) {
    return this.http
      .post<any>(
        `${environment.apiEndPoint
        }/vendor/tourpaymentdetails/?tour_id=${tourid}`,
        data
      )
      .pipe(
        map(up => {
          console.log('<><>><><><>', up);
          return up;
        })
      );
  }
  deletepakcage(tourid) {
    return this.http
      .post<any>(
        `${environment.apiEndPoint}/vendor/tour/deletepackage/${tourid}/`,
        null
      )
      .pipe(
        map(up => {
          console.log('<><>><><><>', up);
          return up;
        })
      );
  }

  getAddreesFromGoogleCoord(lang, lat) {
    return this.http
      .get<any>(
        `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/geocode/json?latlng=${lang},${lat}&key=AIzaSyD1uXumOcpOZ5iLZXJe2lwEzn-NT0cw2tw`, {
        headers: {
          'Access-Control-Allow-Origin': "*",
          'Access-Control-Allow-Headers': "*",
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE'
        }
      }

      )
      .pipe(
        map(up => {
          return up;
        })
      );
  }

  getGooglePulsCode(code) {
    return this.http
      .get<any>(
        // `https://cors-anywhere.herokuapp.com/https://plus.codes/api?address=${code},Cairo&key=AIzaSyD1uXumOcpOZ5iLZXJe2lwEzn-NT0cw2tw`,
        `${environment.apiEndPoint}/vendor/pluscode/?code=${code}`,
        {
          headers: new HttpHeaders(`${environment.httpHeader}`)
        }
      )
      .pipe(
        map(response => {
          console.log(response, 'payment')
          return response;
        })
      );
  }
  // http://prod.zabatnee.com/api/vendor/tourduplicate/63335a34-0196-48cc-8348-ee3f83143ee3/
  postmonth(id) {
    return this.http
      .post<any>(
        `${environment.apiEndPoint}/vendor/tour/tourduplicate/${id}/`,
        null
      )
      .pipe(
        map(up => {
          console.log('<><>><><><>', up);
          return up;
        })
      );
  }

  //send notify
  sendnotify(body) {
    return this.http
      .post<any>(
        `${environment.apiEndPoint}/vendor/reservation/notify`,
        body
      )
      .pipe(
        map(up => {
          console.log('<><>><><><>', up);
          return up;
        })
      );
  }

  // add Contact data branch
  addContactDataBranch(body) {
    return this.http
      .post<any>(
        `${environment.apiEndPoint}/cr/branch/`,
        body
      )
      .pipe(
        map(up => {
          console.log('<><>><><><>', up);
          return up;
        })
      );
  }
  // add Contact data branch
  getContactDataBranch(id) {
    return this.http
      .get<any>(
        `${environment.apiEndPoint}/cr/branch/?branch_guid=${id}`,
        {
          headers: new HttpHeaders(`${environment.httpHeader}`)
        }
      )
      .pipe(
        map(response => {
          console.log(response, 'payment')
          return response;
        })
      );
  }
  // add Contact data branch
  editContactDataBranch(body) {
    return this.http
      .patch<any>(
        `${environment.apiEndPoint}/cr/branch/`,
        body
      )
      .pipe(
        map(up => {
          console.log('<><>><><><>', up);
          return up;
        })
      );
  }
}
