import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import * as S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root'
})
export class UploadimgService {
  accessKeyId: any;
  secretAccessKey: any;
  constructor(private http: HttpClient) { }
  getAmzonCode() {
    return this.http
      .get<any>(
        `${environment.apiEndPoint}amazon/`,
        {
          headers: new HttpHeaders(`${environment.httpHeader}`)
        }
      )
      .pipe(
        map(response => {
          console.log(response, 'activity/vendor/')

          localStorage.setItem('AmzToken', JSON.stringify(response));


          var amzToken = JSON.parse(localStorage.getItem('AmzToken'));
          console.log(amzToken)

          this.accessKeyId = amzToken['keys']['accessKey'];
          this.secretAccessKey = amzToken['keys']['secretKey'];
          console.log(this.accessKeyId, this.secretAccessKey);


          return response;
        })
      );

  }
  uploadFile(file, path) {
    console.log('file in service of image', file);
    console.log('path in service of image', path);
    var amzToken = JSON.parse(localStorage.getItem('AmzToken'));
    console.log(amzToken)
    this.accessKeyId = amzToken['keys']['accessKey'];
    this.secretAccessKey = amzToken['keys']['secretKey'];
    console.log(this.accessKeyId, this.secretAccessKey);

    console.log(file);
    const bucket = new S3({
      accessKeyId: this.accessKeyId,
      secretAccessKey: this.secretAccessKey,
      region: 'us-west-2'
    });

    const params = {
      Bucket: 'zabatnee-backend-storage',
      Key: path + '/' + file.name,
      ContentType: '',
      Body: file,
      ContentEncoding: 'base64'
    };
    const options = {
      // Part Size of 10mb
      partSize: 10 * 1024 * 1024,
      queueSize: 1,
      pathname: '/vendor',
      // Give the owner of the bucket full control
      ACL: 'bucket-owner-full-control'
    };
    bucket.upload(params, options, (err, data) => {
      if (err) {
        console.log('There was an error uploading your file: ', err);
        return false;
      }

      console.log('Successfully uploaded file.', data);
      return true;
    });

  }
  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}
