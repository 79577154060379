import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GetProfileService } from '../../_services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  links: any;
  date: Date;
  category_id: any;
  name: any;

  constructor(
    private router: Router,
    private getProfileService: GetProfileService
  ) {
    this.date = new Date();
  }

  ngOnInit() {
    this.getProfileService.getProfile().subscribe((user: any) => {
      // console.log(user , ' s')
      this.category_id = user.vendor.category_id;

      switch (this.category_id) {
        case 1:
          this.name = user.vendor.name;
          this.links = [
            { path: '/R/dashboard', icon: 'home', label: 'Dashboard' },
            {
              path: '/R/reservations',
              icon: 'card_travel',
              label: 'Reservations'
            },
            { path: '/R/branches', icon: 'view_headline', label: 'Branches' },
            { path: '/R/events', icon: 'event', label: 'Events' },
            {
              path: '/R/globalSettings',
              icon: 'people',
              label: 'Global Settings'
            },
            { path: '/guestList', icon: 'people', label: 'Guest List' },
            { path: '/financial', icon: 'attach_money', label: 'Financial' },
            { path: '/manage-users', icon: 'people', label: 'Manage Users' },
            { path: '/R/reviews', icon: 'star', label: 'Reviews' }

          ];
          break;
        case 2:
          this.name = user.vendor.name;
          this.links = [
            { path: '/dashboard', icon: 'home', label: 'Dashboard' },
            {
              path: '/reservations',
              icon: 'card_travel',
              label: 'Reservations'
            },
            { path: '/tours', icon: 'desktop_windows', label: 'Activities' },
            {
              path: '/globalSettings',
              icon: 'settings',
              label: 'Global Settings'
            },
            { path: '/guestList', icon: 'people', label: 'Guest List' },
            { path: '/financial', icon: 'attach_money', label: 'Financial' },
            { path: '/manage-users', icon: 'security', label: 'Manage Users' }
          ];
          break;
        case 3:
          this.name = user['vendor']['name'];
          this.links = [
            { path: '/concerts/dashboard', icon: 'home', label: 'Dashboard' },
            { path: '/concerts/events', icon: 'event', label: 'Events' },
            // {
            // 	path: '/concerts/venues',
            // 	icon: 'burst_mode',
            // 	label: 'Venues'
            // },
            {
              path: '/concerts/reservations',
              icon: 'list',
              label: 'Reservations'
            },
            { path: '/concerts/check-in', icon: 'print', label: 'Check In' },
            {
              path: '/globalSettings',
              icon: 'settings',
              label: 'Global Settings'
            },
            { path: '/financial', icon: 'attach_money', label: 'Financial' },

            { path: '/manage-users', icon: 'people', label: 'Manage Users' }
          ];
          break;
        case 4:
          this.name = user.vendor.name;
          this.links = [
            { path: '/g/dashboard', icon: 'home', label: 'Dashboard' },
            {
              path: '/g/member-ship',
              icon: 'subscriptions',
              label: 'Membership list'
            },
            {
              path: '/g/list_of_courses',
              icon: 'fitness_center',
              label: 'list of courses'
            },
            {
              path: '/g/attendence',
              icon: 'insert_invitation',
              label: 'Attendance'
            },
            // { path: '/g/Courses', icon: 'group_work', label: 'Courses' },
            // { path: '/g/Branchs', icon: 'location_on', label: 'Branch' },
            { path: '/g/trainers', icon: 'group_add', label: 'Trainers' },
            {
              path: '/globalSettings',
              icon: 'settings',
              label: 'Global Settings'
            },
            { path: '/financial', icon: 'attach_money', label: 'Financial' },

            { path: '/manage-users', icon: 'people', label: 'Manage Users' }
          ];
          break;
      }
    });
  }

  logout() {
    Swal({
      title: 'Do you want to log out?',
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No, cancel'
    }).then(isConfirm => {
      if (isConfirm) {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('Amz');

        this.router.navigate(['/login']);
      } else {
        Swal('Cancelled', '', 'error');
      }
    });
  }
}
